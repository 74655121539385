<template lang="pug">
v-container
  v-row.py-4(color='gray' v-for="(round, index) in timeline", :key="index")
    v-col.text-center
      .text-h3.my-4 {{ $t('timeline.round') }} {{ round.round }}
      v-btn(
        large,
        color="green",
        dark,
        v-if="round.status == 'active'",
        @click="goRound(round)"
      )
        v-icon(left) mdi-help-circle
        | {{ $t('btn.playRound') }}
      .text-caption(v-if="round.cat") 
        b.mr-2 {{ $t('timeline.category') }}: {{ round.cat }}
        | {{ round.q }}
      .text-caption.text-color-gray(v-if="round.status == 'new'") 
        | {{ $t('timeline.notReadyYet') }}
  v-row 
    v-col.text-center
</template>
<script>
export default {
  data() {
    return {
      timeline: [
        { round: 12, type: "q", status: "new" },
        { round: 11, type: "q", status: "new" },
        { round: 10, type: "q", status: "active" },
        {
          round: 9,
          type: "q",
          status: "passed",
          q: "Your car type?",
          cat: "moto",
        },
        { round: 8, type: "q", status: "passed" },
        { round: 7, type: "q", status: "passed" },
        { round: 6, type: "q", status: "passed" },
      ],
    };
  },
  methods: {
    goRound(round) {
      this.$router.push({ name: "round", params: { round: round.round } });
    },
  },
};
</script>